import * as React from 'react';
import Image from 'gatsby-image';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { IoMdArrowRoundBack } from 'react-icons/io';
import {
  NotFoundWrapper,
  NotFoundContent,
  NotFoundImage,
  Goback,
  Icon,
} from './style';

interface NotFoundProps {}

const NotFound: React.FunctionComponent<NotFoundProps> = () => {
  // const Data = useStaticQuery(graphql`
  //   query {
  //     avatar: file(absolutePath: { regex: "/404.png/" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 750, quality: 100) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //     site {
  //       siteMetadata {
  //         author
  //         about
  //       }
  //     }
  //   }
  // `);

  return (
    <NotFoundWrapper>
      <NotFoundContent>
        <h1>いつもジオグラフをご利用いただきありがとうございます。</h1>
        <p>
          このページのURLは変更されました。大変恐れ入りますが、トップページより再度、ご探究ください。
        </p>
        <Goback>
          <Link to="/">
            <Icon>
              <IoMdArrowRoundBack />
            </Icon>
            トップページに戻る
          </Link>
        </Goback>
      </NotFoundContent>
      {/* <NotFoundImage>
        <Image fluid={Data.avatar.childImageSharp.fluid} alt="author" />
      </NotFoundImage> */}
    </NotFoundWrapper>
  );
};

export default NotFound;
